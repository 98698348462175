.rwt__tabs[data-rwt-vertical="true"] {
  display: flex;
}

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  display: flex;
  align-content: center;
  justify-content: center;
}

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 1rem;
}

.rwt__tab {
  background: transparent;
  border: 0;
  width: 20%;
  filter: grayscale(100%);
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2rem;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

@media only screen and (max-width: 40em) {
  .rwt__tab {
    padding: 0.5rem 0.5rem;
  }
}

.rwt__tab[aria-selected="falrwt__tabse"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #c1e6ee;
}

.rwt__tab[aria-selected="true"] {
  position: relative;
  filter: none;
}

.rwt__tab[aria-selected="true"]:after {
  content: "";
  position: absolute;
}

.rwt__tablist:not([aria-orientation="vertical"])
  .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #54bace;
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 3px solid #54bace;
}
